@import "./variables.scss";
@import "./theme-definitions";

@mixin media-laptop {
  @media screen and (max-width: #{$laptop-width}) {
    @content;
  }
}

@mixin media-tablet {
  @media screen and (max-width: #{$tablet-width}) {
    @content;
  }
}

@mixin media-mobile {
  @media screen and (max-width: #{$mobile-width}) {
    @content;
  }
}

// Themify implementation idea taken from
// https://medium.com/@dmitriy.borodiy/easy-color-theming-with-scss-bc38fd5734d1
@mixin themify($themes: $themes) {
  @each $theme, $map in $themes {
    :global(.theme-#{$theme}) & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }

      @content;
      $theme-map: null !global;
    }
  }
}

// Special case for the body element
@mixin themify-global($themes: $themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }

      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}
